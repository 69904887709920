import React, { useState, useRef, useEffect } from "react";
// Import Styles
import "./styles/app.scss";
// Adding Components
import { Nav } from "./components/Nav";
import { Player } from "./components/Player";
import { Song } from "./components/Song";
import { Library } from "./components/Library";
import { Status } from "./components/Status";
// import Music
import data from "./data";

function App() {
  // Ref
  const audioRef = useRef(null);
  // State
  const [songs, setSongs] = useState(data());
  const [currentSong, setCurrentSong] = useState(songs[0]);
  const [rndNum, setRndNum] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [songInfo, setSongInfo] = useState({
    currentTime: 0,
    duration: 0,
    animationPercentage: 0,
  });
  const [libraryStatus, setLibraryStatus] = useState(false);
  const [serverOnline, setServerOnline] = useState(false);
  const [musicVolume, setMusicVolume] = useState(0.1);

  const timeUpdateHandler = (e) => {
    const current = e.target.currentTime;
    const duration = e.target.duration;
    // Calculate Percentage
    const roundedCurrent = Math.round(current);
    const roundedDuration = Math.round(duration);
    const animation = Math.round((roundedCurrent / roundedDuration) * 100);

    setSongInfo({
      ...songInfo,
      currentTime: current,
      duration,
      animationPercentage: animation,
    });
  };

  useEffect(() => {
    audioRef.current.volume = musicVolume;
  }, [musicVolume]);

  const activeLibraryHandler = (nextPrev) => {
    const newSong = songs.map((song) => {
      if (song.id === nextPrev.id) {
        return {
          ...song,
          active: true,
        };
      } else {
        return {
          ...song,
          active: false,
        };
      }
    });
    setSongs(newSong);
  };

  const songEndHandler = async () => {
    let currentIndex = songs.findIndex((song) => song.id === currentSong.id);
    await setCurrentSong(songs[(currentIndex + 1) % songs.length]);
    activeLibraryHandler(songs[(currentIndex + 1) % songs.length]);
    if (isPlaying) {
      audioRef.current.load();
      audioRef.current.play();
    }
  };

  return (
    <div className={`App ${libraryStatus && "libraryActive"}`}>
      <Nav libraryStatus={libraryStatus} setLibraryStatus={setLibraryStatus} />
      <Status serverOnline={serverOnline} />
      <Song
        setRndNum={setRndNum}
        isPlaying={isPlaying}
        onServerStatus={(value) => setServerOnline(value)}
      />
      <Player
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        audioRef={audioRef}
        songInfo={songInfo}
        musicVolume={musicVolume}
        setMusicVolume={setMusicVolume}
        rndNum={rndNum}
      />
      <Library libraryStatus={libraryStatus} />
      <audio
        preload="auto"
        onTimeUpdate={timeUpdateHandler}
        onLoadedMetadata={timeUpdateHandler}
        ref={audioRef}
        src={process.env.REACT_APP_BDAY_STREAM_URL}
        onEnded={songEndHandler}
      />
    </div>
  );
}

export default App;
