import React from "react";

export const Status = ({ serverOnline }) => {
  return (
    <div className="status">
      <div
        className="pulsating-circle"
        style={{
          "--my-color-var-before": serverOnline ? "#35c827" : "#ed6199",
          "--my-color-var-after": serverOnline ? "#35c827" : "#ed6199",
        }}
      />
    </div>
  );
};
