import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

export const Nav = ({ libraryStatus, setLibraryStatus }) => {
  return (
    <nav>
      <div>
        <h1>Markus Ort's</h1>
        <h2 className="subString">Webradio - Player</h2>
      </div>
      <button onClick={() => setLibraryStatus(!libraryStatus)}>
        {!libraryStatus ? "Tracklist " : "Close "}
        <FontAwesomeIcon icon={faMusic} />
      </button>
    </nav>
  );
};
