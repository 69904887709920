import React, { useEffect, useState } from "react";

export const Countdown = ({ onFinished, endTime }) => {
  // States
  const [time, setTime] = useState("09:00:00");

  // UseEffects
  useEffect(() => {
    startCountdown();
  }, []);

  // Handler
  const startCountdown = () => {
    // Update the countdown every second
    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const distance = endTime - now;

      // Calculate the hours, minutes, and seconds remaining
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTime(
        hours.toString().padStart(2, "0") +
          ":" +
          minutes.toString().padStart(2, "0") +
          ":" +
          seconds.toString().padStart(2, "0")
      );
      // When the countdown is finished, clear the interval
      if (distance < 0) {
        clearInterval(countdown);
        onFinished();
      }
    }, 1000);
  };

  return (
    <>
      <h2>{"Stream starts in:"}</h2>
      <h3>{time}</h3>
    </>
  );
};
