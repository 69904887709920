import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faAngleLeft,
  faAngleRight,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import graphicData from "../graphic";

export const Player = ({
  isPlaying,
  setIsPlaying,
  audioRef,
  songInfo,
  musicVolume,
  setMusicVolume,
  rndNum,
}) => {
  const graphicColor = graphicData();

  // Event Handlers
  const playSongHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.load();
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // https://stackoverflow.com/questions/43577182/react-js-audio-src-is-updating-on-setstate-but-the-audio-playing-doesnt-chang/64981388#64981388

  const getInternetTime = (time) => {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  };

  const volumeHandler = (e) => {
    const position = e.target.value;
    //audioRef.current.volume = position;
    setMusicVolume(position);
  };

  // Add Styles
  const trackRadio = {
    transform: `translateX(100%)`,
  };

  return (
    <div className="player">
      <div className="timeControl">
        <div
          style={{
            background: `linear-gradient(to right, ${graphicColor[1].color[rndNum][0]}, ${graphicColor[1].color[rndNum][1]})`,
            pointerEvents: "none",
          }}
          className="track"
        >
          <input
            min={0}
            max={songInfo.duration || 0}
            value={songInfo.currentTime}
            type="range"
            style={{ cursor: "auto" }}
          />
          <div style={trackRadio} className="animateTrack" />
        </div>
        <p>
          {!songInfo.duration ? "0:00" : getInternetTime(songInfo.currentTime)}
        </p>
      </div>
      <div className="playControl">
        <FontAwesomeIcon
          icon={faAngleLeft}
          size="2x"
          color="lightgray"
          style={{ cursor: "auto" }}
        />
        <FontAwesomeIcon
          onClick={playSongHandler}
          className="play"
          icon={isPlaying ? faPause : faPlay}
          size="2x"
        />
        <FontAwesomeIcon
          style={{ cursor: "auto" }}
          color="lightgray"
          icon={faAngleRight}
          size="2x"
        />
      </div>
      <div className="heat-slider">
        <div className="heat-bar">
          <div className="clip"></div>
        </div>
        <input
          type="range"
          min={0}
          max={1}
          step={0.01}
          onChange={volumeHandler}
          value={musicVolume}
        />
      </div>
    </div>
  );
};
