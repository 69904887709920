import React, { useEffect, useState } from "react";

export const Library = ({ libraryStatus }) => {
  const request =
    "https://server4.streamserver24.com:2199/external/rpc.php?callback=jQuery19105981435819350047_1676576278882&m=recenttracks.get&username=markus&charset=&mountpoint=&rid=markus&_=1676576278883";
  const originUrl = window.location.origin;

  // States
  const [trackList, setTrackList] = useState([]);
  const [loading, setLoading] = useState(true);

  // UseEffects
  useEffect(() => {
    loadData();
    const getData = setInterval(() => loadData(), 60000);
    return () => clearInterval(getData);
  }, []);

  // Handler
  const loadData = async () => {
    const response = await fetch(request, { cache: "no-store" });
    const res = await response.text();
    const { data } = JSON.parse(
      res.substring(res.indexOf("(") + 1, res.lastIndexOf(")"))
    );

    // Set all states
    setTrackList(data[0]);
    setLoading(false);
  };

  return (
    <div className={`library ${libraryStatus && "activeLibrary"}`}>
      <div className="libraryHeader">
        <h2>{`Last 10 played Songs:`}</h2>
      </div>
      <div className="libraryList" />
      {loading ? (
        <p>Loading ...</p>
      ) : (
        trackList.map((track) => (
          <div className="librarySong collection">
            <div
              style={{
                height: 60,
                width: 60,
              }}
            >
              <img
                src={track.image}
                alt={track.title}
                height={"100%"}
                width={"100%"}
              />
            </div>
            <div>
              <h3>{track.title}</h3>
              <h4>{track.artist}</h4>
              {track.url.includes(originUrl) ? (
                <a href>{""}</a>
              ) : (
                <a href={track.url} target="_blank" rel="noreferrer">
                  {"Buy Album"}
                </a>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};
