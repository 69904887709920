import React, { useEffect, useState } from "react";
import picture from "../images/picture.jpg";
import vinyl from "../images/vinyl-blue.png";
import unknown from "../images/unknown.jpg";
import { Countdown } from "./Countdown";

export const Song = ({ setRndNum, isPlaying, onServerStatus }) => {
  const countDownEnd = process.env.REACT_APP_COUNTDOWN_DATE;
  const endTime = new Date(countDownEnd);
  const now = new Date().getTime();

  // States
  const [interpret, setInterpret] = useState("");
  const [imageUrl, setImageUrl] = React.useState("");
  const [songTitle, setSongTitle] = React.useState("");
  const [serverStatus, setServerStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [countDownFinished, setCountdownFinished] = React.useState(
    endTime - now < 0
  );

  const request =
    "https://server4.streamserver24.com:2199/external/rpc.php?callback=jQuery191039166380652676036_1676324981728&m=streaminfo.get&username=markus&charset=&mountpoint=&rid=markus&_=1676324981729";

  // USeEffects
  useEffect(() => {
    const num = Math.floor(Math.random() * 7) + 1;
    setRndNum(num - 1);
  }, [songTitle]);

  useEffect(() => {
    loadData();
    const getData = setInterval(() => loadData(), 10000);
    return () => clearInterval(getData);
  }, []);

  useEffect(() => {
    // Server is offline
    if (!serverStatus && !loading) {
      onServerStatus(false);
      setLoading(true);
    } else if (serverStatus && !loading) {
      // Server is online and loaded everything
      onServerStatus(true);
    }
  }, [loading, serverStatus]);

  // Handler
  const loadData = async () => {
    const response = await fetch(request, { cache: "no-store" });
    const res = await response.text();
    const { data } = JSON.parse(
      res.substring(res.indexOf("(") + 1, res.lastIndexOf(")"))
    );
    const status = data[0].offline;
    const { artist, title, imageurl } = data[0].track;

    // Set all states
    setInterpret(artist);
    setSongTitle(title);
    setImageUrl(imageurl);
    setServerStatus(!status);
    setLoading(false);
  };

  return (
    <div className="songContainer">
      {!loading && imageUrl.length > 0 && imageUrl.endsWith("nocover.png") && (
        <p
          style={{
            position: "absolute",
            zIndex: "1",
            color: "white",
            border: "1px solid white",
            fontSize: "1.4rem",
            top: "30%",
            fontWeight: "bold",
          }}
        >
          Unknown Cover
        </p>
      )}
      <img
        className={isPlaying ? "song-playing" : ""}
        src={
          loading
            ? unknown
            : imageUrl.length > 0 && imageUrl.endsWith("nocover.png")
            ? vinyl
            : imageUrl.length > 0
            ? imageUrl
            : picture
        }
        alt={loading ? "loading..." : interpret + " - " + songTitle}
      />
      {countDownFinished ? (
        loading ? (
          <>
            <h2>Loading...</h2>
            <h3> </h3>
          </>
        ) : (
          <>
            <h2>{interpret}</h2>
            <h3>{songTitle}</h3>
          </>
        )
      ) : (
        <Countdown
          endTime={endTime}
          onFinished={() => {
            setCountdownFinished(true);
          }}
        />
      )}
    </div>
  );
};
